<template>
  <CustomerSupport />
</template>


<script>
export default {
  name: "CustomerSupportComp",
  data() {
    return {
      breadcrumbs: [{text:"Home", path:"/dashboard", isActive: false}, {text:"Customer Supports", path:"", isActive: true}] 
    }
  },
  components: {
    CustomerSupport: () => import("../../../src/components/customerSupport/CustomerSupports"),
  },
  created() {
    this.$store.dispatch("Config/setBreadCrumb", this.breadcrumbs);
  },
};
</script>